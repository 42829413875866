<template>
  <b-card>
    <g-form @submit="post">
      <b-row>
        <!-- from month -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.month"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="month"
              field="select"
              name="fromMonth"
              :options="months"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- from year -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.year"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="year"
              field="select"
              name="fromYear"
              :options="years"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              :disabled="selectedItem.departmentId || selectedItem.divisionId"
              label-text="employee"
              field="select"
              name="employee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="!selectedItem.employeeId"
              :disabled="selectedItem.divisionId || selectedItem.employeeId"
              label-text="department"
              field="select"
              name="departments"
              :options="departments"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.divisionId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="!selectedItem.employeeId"
              :disabled="selectedItem.departmentId || selectedItem.employeeId"
              label-text="division"
              field="select"
              name="divisions"
              :options="divisions"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- </b-row>
      <b-row> -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.depId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="selectedItem.employeeId"
              :disabled="true"
              label-text="department"
              field="select"
              name="departments"
              :options="departments"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.diviId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="selectedItem.employeeId"
              :disabled="true"
              label-text="division"
              field="select"
              name="divisions"
              :options="divisions"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
            v-permission="'addCalculateSalary'"
          >
            {{ $t('calc') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import { months, years } from '@/libs/acl/Lookups';

export default {
  props: ['id'],
  data() {
    return {
      items: [],
      fields: [],
      selectAll: false,
      employees: [],
      departments: [],
      divisions: [],
      months: months,
      years: years,
      selectedItem: {
        month: '',
        year: '',
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'actions',
        },
        {
          key: 'transactions',
        },
      ];
    },
  },
  watch: {
    'selectedItem.employeeId'(employeeId) {
      const emp = this.employees.find((val) => val.id === employeeId);
      this.selectedItem.diviId = emp.divisionId;
      this.selectedItem.depId = emp.departmentId;
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.getEmployee();
    this.getDepartMent();
    this.getDivision();
  },
  methods: {
    // get all employees
    getEmployee() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data.filter((x) => !x.isSuspended);
      });
    },
    // get all departments
    getDepartMent() {
      this.get({ url: 'departments' }).then((data) => {
        this.departments = data;
      });
    },
    // get all divison
    getDivision() {
      this.get({ url: 'divisions' }).then((data) => {
        this.divisions = data;
      });
    },
    validateData() {
      // must select month and year
      if (this.selectedItem.month === '' || this.selectedItem.year === '') {
        return false;
      }
      return true;
    },
    validateYear(year, month) {
    var checkDate = new Date(year, month - 1, 1);
    var date = this.getDate(checkDate)
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    post() {
      if (!this.validateData()) {
        this.doneAlert({
          text: this.$t('mustSelectMonthAndYear'),
          type: 'warning',
          timer: 10000,
        });
        return;
      }
      if (!this.validateYear(this.selectedItem.year, this.selectedItem.month)) return;
      if (this.selectedItem.employeeId && this.selectedItem.departmentId && this.selectedItem.divisionId) {
        this.doneAlert({
          text: this.$t('mustSelectEmployeeOrDepartmentOrDivision'),
          type: 'warning',
          timer: 10000,
        });
        return;
      }
      this.create({
        url: 'CalculateSaleries',
        data: this.selectedItem,
      })
        .then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
        })
        .catch(({ data }) => {
          this.doneAlert({ title: data.errors[0].message, type: 'error' });
        });
    },
  },
};
</script>
